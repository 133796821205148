
import { ScrapedArticle, ScrapeTask } from "@/types";
import { Component, Vue, Watch } from "vue-property-decorator";
import { formatRelative } from "date-fns";
import nlBe from "date-fns/locale/nl-BE";

import * as ScraperServices from '@/services/ScraperService';
import { ROOT_ACTIONS } from "@/store/actions";

@Component({
  components: {
    UseScrapedArticleDialog: () => import('@/components/dialogs/UseScrapedArticleDialog.vue'),
  },
})
export default class Index extends Vue {
  scrapeTasks = [] as ScrapeTask[];

  articles = [] as ScrapedArticle[];
  totalCount = 0;

  lastItemOnPage = null as any;

  selectedArticle = null as ScrapedArticle | null;
  
  options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  } as any;

  headers = [
    { text: "Titel", value: "title" },

    { text: "FCO", value: "PartnerId", align: "center" },
    { text: 'CRV', value: 'claimReviewData', align: 'center' },
    { text: "Datum", value: "date", width: 150 },
    { text: "FCP", value: "used", sortable: false, align: "center" },
    { text: "Link", value: "url", sortable: false },
    { text: "Aanmaken", value: "language", align: "center", sortable: false },
  ];



  loading = {
    lastAction: true,
    table: true,
    scraperStart: false,
  };

  dialogs = {
    scrapeNow: false,
    useArticleDialog: false,
  };

  @Watch('options', { deep: true, immediate: true })
  async onOptionsChanged() {
    this.loading.table = true;

    const limit = this.options.itemsPerPage;
    const offset = (this.options.page - 1) * limit;
    const { rows, count } = await ScraperServices.GetScrapedArticles(limit, offset);
    this.totalCount = count;
    this.articles = rows;
    this.loading.table = false;
  }

  async mounted(): Promise<void> {
    this.scrapeTasks = await ScraperServices.GetScrapeTasks(1);
    this.loading.lastAction = false;
    this.loading.table = false;
  }

  selectArticle(article: ScrapedArticle) {
    this.selectedArticle = article;
    this.dialogs.useArticleDialog = true;
  }

  formatRelativeDate(d: Date): string {
    return formatRelative(d, new Date(), {
      locale: nlBe,
    });
  }

  formatDate(d: string): string {
    return formatRelative(new Date(d), new Date(), {
      locale: nlBe,
    });
  }

  async startScraper() {
    try {
      this.loading.scraperStart = true;
      await ScraperServices.ExecuteScraper();
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Scraper word zo dadelijk gestart'
      })
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'Scraper kon niet gestart worden'
      })
    } finally {
      this.loading.scraperStart = false;
    }
  }
}
